import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const barStyle = (
  width,
  height = "3",
  backgroundColor = "clear",
  duration = "1"
) => ({
  margin: 0,
  padding: 0,
  position: "absolute",
  top: 0,
  zIndex: "99",
  backgroundColor: `${backgroundColor}`,
  height: `${height}px`,
  width: `${width}`,
  transitionProperty: "width",
  transitionDuration: `${duration}s`,
  transitionTimingFunction: `ease-out`
})

const ProgressBar = props => {
  const [width, setWidth] = useState(null)

  const scrollEventHandler = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    const scrolled = (winScroll / height) * 100
    if (height > 0) {
      setWidth(`${scrolled}%`)
    } else {
      setWidth(null)
    }
  }

  useEffect(() => {
    try {
      window.addEventListener("scroll", scrollEventHandler)
    } catch (error) {
      console.error(error)
    }

    return () => {
      try {
        window.removeEventListener("scroll", scrollEventHandler)
      } catch (error) {
        console.error(error)
      }
    }
  }, [])

  const { height, color, duration } = props
  return <div style={barStyle(width, height, color, duration)} />
}

ProgressBar.propTypes = {
  height: PropTypes.number,
  duration: PropTypes.number,
  color: PropTypes.string.isRequired
}

export default ProgressBar
