import React from "react"
import PropTypes from "prop-types"
import t from "../../translations"
import PageHeadForm from "../../components/PageHead1/PageHeadForm"
import { getNewsletterForm } from "../../utilities/newsletterForms"

const NewsletterBlock = props => {
  const { locale } = props

  const { formId, portalId } = getNewsletterForm(locale)

  return (
    <div>
      <h2 className="h5">
        {t.translate(t.KEYS.SUBSCRIBE_TO_NEWSLETTER, locale)}
      </h2>
      <PageHeadForm
        portalId={portalId}
        formId={formId}
        formStyle="inline"
        locale={props.locale}
      />
    </div>
  )
}

NewsletterBlock.propTypes = {
  locale: PropTypes.string.isRequired
}

export default NewsletterBlock
