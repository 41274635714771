import _ from "lodash"
import React from "react"

export const categorySchema = (
  blogPost,
  category,
  pageUrl,
  publishedOn,
  updatedOn
) => {
  if (!(blogPost && category && pageUrl)) {
    return null
  }

  const scripts = category.schemaScripts

  if (!scripts) {
    return null
  }

  return scripts.map(script => {
    if (!script) {
      return null
    }

    const attrs = {}
    if (script.id) {
      attrs.key = script.id
    }

    if (script.type) {
      attrs.type = script.type
    }

    if (script.source) {
      attrs.src = script.source
    }

    let type = "script"
    if (script.tagType && script.tagType === "noscript") {
      type = "noscript"
    }

    let body = _.get(script, "body.body", null)
    if (!body) {
      return null
    }

    body = body.replace("{{page_url}}", pageUrl)
    body = body.replace("{{article_title}}", _.get(blogPost, "title", ""))
    body = body.replace(
      "{{article_meta_description}}",
      _.get(blogPost, "metaDescription.metaDescription", "")
    )

    const imageFileUrl = _.get(blogPost, "mainImage.file.url", null)
    if (imageFileUrl) {
      body = body.replace("{{article_image_url}}", imageFileUrl)
    }

    body = body.replace(
      "{{article_author}}",
      _.get(blogPost, "author.name", "")
    )
    body = body.replace("{{article_published_on_date}}", publishedOn)
    body = body.replace("{{article_updated_on_date}}", updatedOn)

    switch (type) {
      case "noscript":
        return (
          <noscript
            {...attrs}
            dangerouslySetInnerHTML={{
              __html: body
            }}
          ></noscript>
        )
      default:
        return <script {...attrs}>{body}</script>
    }
  })
}
